.task-comments-container {
    margin: 0 auto;
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
  }
  
  .comment {
    padding: 8px;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .comment:last-child {
    border-bottom: none;
  }
  
  .comment-header {
    font-size: 14px;
    color: #333;
    margin-bottom: 4px;
  }
  
  .timestamp {
    font-size: 12px;
    color: #888;
    margin-left: 5px;
  }
  
  .comment-body {
    font-size: 11px;
    color: #222;
    background: white;
    padding: 6px 10px;
    border-radius: 5px;
    margin-top: 4px;
  }
  
  .add-comment-btn {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
  }
  
  .comment-form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
  .comment-actions {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }