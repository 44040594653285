.flashing-circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    animation: flash 1s infinite;
  }
  
  @keyframes flash {
    0%, 100% { opacity: 1; }
    50% { opacity: 0.5; }
  }

  .status-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }