.jobsList .groupedRow td {
    padding: 0;
}
.jobsList .groupedRow td br {
    display: none;
}
.jobsList .groupedRow td small span {
    width: 100%;
    float: left;
}
.jobsList .groupedRow td div {
    padding: 10px 10px;
    float: left;
    width: 100%;
    border-top: 1px solid #dee2e6;
    overflow-y: auto;
    height: 60px;
}
.jobsList .groupedRow td div small {
    float: left;
    width: 100%;
}
.jobsList .groupedRow td div:first-child {
    border-top: none;
}
.jobsList .groupedRow td:first-child {
    padding: 0.75rem;
}

.first-row-header {
    max-width: 15% !important;
  }
