.homepage-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 15px;
  margin-left: 14%;
  margin-right: 14%;
}

.homepage-header {
  text-align: center;
  font-weight: 700;
  color: #343a40;
}

.homepage-small {
  display: block;
  margin-bottom: 10px;
  font-size: 12px;
  color: #6c757d;
}

.homepage-button {
  display: block;
  color: #007bff;
  text-decoration: none;
}

.homepage-icon {
  margin-right: 5px;
}

.homepage-create-account {
  margin-top: 20px;
}
