.carousel .slide img {
    width: 25% !important;
}
.link-btn-layout {
    line-height: 12px;
    padding: 0px;
    margin: 0px;
    display: flex;
    justify-content: left;
    text-decoration: underline;
    text-align: left;
}
