.header {
  position: relative;
  z-index: 3;
}

.navigation {
  position: relative;
  z-index: 2;
}

.footer {
  position: relative;
  z-index: 1;
}