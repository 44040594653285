#jobSearchFilterInfiniteTable {
    position: absolute;
    z-index: 1;
    background-color: white;
    width: 95%;
}

.appliedSearchFilter {
    background-color: rgba(0, 0, 0, 0.075);
}
