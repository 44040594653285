.ps-collapsed {
    width: 60px !important;
    min-width: 50px !important;
}

.css-dip3t8 {
    background-color: transparent !important;
}

.sidebar-container {
  display: flex;
  flex: 1;
}
.menu-item-active {
  background-color: #dbdbdb;
  color: black;
}