.filepond--credits {
    display: none;
}

.nav-tabs .nav-link {
    color: #495057 !important;
}

h4 {
    font-weight: 300 !important;
}

.blockbuilder-branding {
    display: none !important;
}

.table-rounded {
  /* Force separate borders so rounding works */
  border-collapse: separate;
  border-spacing: 0;

  /* Slight rounding matches typical Bootstrap form inputs */
  border-radius: 0.25rem;

  /* Keep corners from overflowing */
  overflow: hidden;
}

.table-rounded thead th:first-child {
  border-top-left-radius: 0.35rem;
}

.table-rounded thead th:last-child {
  border-top-right-radius: 0.35rem;
}

.table-rounded tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.35rem;
}

.table-rounded tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.35rem;
}

#datepicker-portal {
  position: absolute;
  z-index: 1050;
}

.apexcharts-legend {
  position: absolute !important;
  right: 10px; /* Adjust these values as needed */
  top: 10px;
  z-index: 10;
}

.dropdown-menu-element {
    z-index: 100000 !important;
}

.nav-link {
  padding: .3rem .5rem !important
}

.overlay-top {
  position: absolute; /* or fixed, depending on your needs */
  z-index: 1000; /* Ensure it's on top */
  background: white; /* Optional: To ensure it overlays correctly */
  width: 100%; /* Adjust as needed */
}

.outline-danger-btn {
    color: #dc3545;
    border: 2px solid #dc3545; 
    border-radius: 4px;
    background-color: white;
    padding: 2px;
    display: inline-block;
  }

.h4 {
    font-weight: 200 !important;
}

.my-custom-modal {
    width: fit-content;
}
.table-container {
    position: relative;
    overflow: auto;
}
.pac-container {
    z-index: 10000 !important;
}
.table-header-fixed {
    position: sticky !important;
    top: 0;
    z-index: 1;
}

.table-fixed th,
.table-fixed td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid #dee2e6;
    position: relative;
    z-index: 0;
}

.table-header-fixed th {
    position: sticky !important;
    top: 0;
    background-color: #fff;
    border-bottom: 2px solid #dee2e6;
}

@media (max-width: 1024px) {
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 130% !important;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: 0;
    }
}

.btn-outline-primary {
    color: rgb(13, 110, 253) !important;
    background-color: #fff !important;
    border-color: #0d6efd !important;
}

.btn-outline-primary:hover {
    color: rgb(136, 144, 155) !important;
    background-color: #fff !important;
    border-color: rgb(136, 144, 155) !important;
}

.btn-outline-danger:hover {
    color: rgb(13, 110, 253) !important;
    background-color: #fff !important;
    border-color: #dc3545 !important;
}

.btn-outline-danger {
    color: #dc3545 !important;
    background-color: #fff !important;
    border-color: #dc3545 !important;
}

.btn-link {
    color: rgb(13, 110, 253);
    cursor: pointer;
    border-color: none !important;
    background-color: #fff !important;
    border-style: none !important;
    border-width: 0px !important;
    border-radius: 0px !important;
}

.btn-link:hover {
    color: rgb(136, 144, 155) !important;
    border-color: none !important;
    background-color: #fff !important;
    border-style: none !important;
    border-width: 0px !important;
    border-radius: 0px !important;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.5 !important;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.pill {
    display: inline !important;
}

.wide-modal {
    width: 90vw !important;
    max-width: 90vw !important;
}

.sort-icon {
    vertical-align: middle;
    font-size: 1rem;
}

.link-danger {
    color: #dc3545 !important;
}

.empty-section {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
  height: '200px';
  text-align: 'center';
  margin-top: '20px';
  margin-bottom: '20px';
  background-color: '#f9f9f9';
  border-radius: '10px';
  padding: '20px';
  box-shadow: '0 4px 8px rgba(0,0,0,0.1)';
  color: '#555';
}

.full-screen-modal .modal-dialog {
    max-width: calc(100% - 30px);
    width: 100%;
    margin: 15px;
  }
  
  .full-screen-modal .modal-content {
    height: 100%;
  }
  
  .full-screen-modal .modal-body {
    overflow-y: auto;
  }

  .sessions-action-buttons .btn {
    line-height: 0 !important;
    font-size: 0.72rem !important;
}

.h1 {
    font-weight: 100 !important;
}

.thin-font {
    font-weight: 100; /* or 200 */
}
.sessions-buttons .btn {
    line-height: 0 !important;
    font-size: 1rem !important;
}

.job-sessions-buttons .btn {
  line-height: 0 !important;
  font-size: 0.8rem !important;
}

/* body {
font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
} */

body {
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
  }
  
  .accordion-toggle-left {
    text-align: left;
  }
  
  /* cyrillic-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu72xKKTU1Kvnz.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5mxKKTU1Kvnz.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7mxKKTU1Kvnz.woff2) format('woff2');
    unicode-range: U+1F00-1FFF;
  }
  /* greek */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4WxKKTU1Kvnz.woff2) format('woff2');
    unicode-range: U+0370-03FF;
  }
  /* vietnamese */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7WxKKTU1Kvnz.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu7GxKKTU1Kvnz.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu4mxKKTU1Kg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  
  
  .hundred {
    min-height: 100vh;
    position: relative;
    margin: 0;
    padding-bottom: 150px;
    box-sizing: border-box;
  }
  
  .admin-color {
    color: purple;
  }
  
  .title-space {
    letter-spacing: 1px;
  }
  
  h1 {
    font-size: 2rem !important;
  }
  
  h2 {
    /* font-size: 1.8rem; */
    font-weight: 300;
  }
  
  h3 {
    /* font-size: 1.6rem; */
    font-weight: 300;
  }
  
  h5 {
    /* font-size: 1.2rem; */
    font-weight: 500;
  }
  
  p {
    font-size: 0.9rem;
  }
  
  small {
    font-size: 0.8rem;
  }
  
  .small {
    font-size: 0.8rem;
  }
  
  .form-label {
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .login-bg {
    background-color: lightgrey;
  }
  
  .action-bg {
    background-color: #e4e4e4;
  }
  
  .nav-more {
    font-size: 80%;
    font-weight: 100;
  }
  
  .modal-title {
    font-weight: 100;
  }
  
  .bolder-text {
    font-weight: 700;
  }
  
  .hover:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  
  .job-hover:hover {
    cursor: pointer;
  }
  
  .job-list-not-allowed:hover {
    cursor: not-allowed;
  }
  
  .numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    text-align: center;
  }
  
  .size-bump {
    font-size: 1.5em;
  }
  
  .special-grey {
    background-color: rgba(0, 0, 0, 0.03);
  }
  
  .footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
  }
  
  .CSVImporter_FileSelector {
    border: gray !important;
    border-width: 2px;
  }
  
  .CSVImporter_FormatRawPreview {
    display: none !important;
  }
  
  .CSVImporter_FormatPreview__header {
    display: none !important;
  }
  
  .CSVImporter_TextButton {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
    display: inline-block;
    font-weight: 100;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
  }
  
  .CSVImporter_TextButton:hover {
    color: #007bff;
    background-color: transparent;
    background-image: none;
    border-color: #007bff;
    cursor: pointer;
    display: inline-block;
    font-weight: 100;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
  }
  
  .text-decoration-underline {
    text-decoration: underline;
  }
  
  .custome-error.invalid-feedback {
    display: block !important;
  }
  
  .pill {
    background-color: #007bff;
    color: black;
    padding: 5px;
    border-radius: 6px;
  }
  
  .special {
    background-color: white !important;
  }
  
  .btn-primary {
    /* font-size: small !important; */
    color: #fff !important;
    background-color: #0d6efd !important;
    border-color: #0d6efd !important;
  }
  
  .btn {
    /* font-size: small !important; */
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529;
    text-align: center !important;
    text-decoration: none !important;
    vertical-align: middle !important;
    cursor: pointer !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    border-radius: 0.25rem !important;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out !important;
  }
  
  .btn-link {
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* font-size: small !important; */
    font-weight: 400 !important;
    color: rgb(13, 110, 253) !important;
    background-color: transparent !important;
    /* text-decoration: underline !important; */
    /* border-bottom: 1px solid rgb(13, 110, 253) !important; */
  }
  
  .btn-link:hover {
    font-weight: 400 !important;
    background-color: transparent !important;
    /* text-decoration: underline !important; */
    /* border-bottom: 1px solid rgb(13, 110, 253) !important; */
  }
  
  .link-danger-hover:hover {
    opacity: 50%;
  }
  
  button:disabled {
    color: gray !important;
  }
  
  .btn-outline-primary {
    color: #0d6efd !important;
    border-color: #0d6efd !important;
  }
  
  .border-opacity-50 {
    border-color: lightgrey !important;
  }
  
  .light-line {
    color: lightgray;
  }
  
  .bg-secondary-light {
    background-color: lightgray;
  }
  
  .label-bold {
    font-weight: 500;
  }