.bottom-right {
  position: fixed;
  bottom: 0; /* No bottom margin */
  right: 20px; /* Right margin */
  width: 20%;
  /* height: 80%; */
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  z-index: 10000;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
}