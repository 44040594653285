#send-notification-users-table .table-header,
#send-notification-users-table .table-row {
  display: flex;
  border: .5px solid #ddd;
}

#send-notification-users-table .table-cell {
  flex: 1 1 0px; /* Assumes equal width for all columns initially */
  padding: 8px;
  border-right: .5px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


#send-notification-users-table .table-cell:last-child {
  flex: 0.25 1 0px;
  border-right: none;
}

#send-notification-users-table .table-cell:first-child {
  flex: 0.45 1 0px;
}

#send-notification-users-table .table-cell:nth-last-child(2) {
  flex: 0.6 1 0px;
}

#send-notification-users-table .table-header {
  font-weight: bold;
}